import React from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Row } from 'react-bootstrap';
function Customers() {
	const [records, setRecords] = React.useState([{
		name: "Equifax ",
		img: "https://assets.equifax.com/global/images/logos/equifax_150_28.svg",
		class: "bg-white  p-2",

	},
	{
		name: "MICM",
		img: "https://micm.gob.do/wp-content/uploads/2022/09/Logo-MICM-Attribute-white.svg",
		class: "bg-dark p-2",
	},
	{
		class: "bg-white p-2",
		name: "Ok Casting",
		img: "https://okcastingrd.com/wp-content/uploads/2019/07/logo_v.png"
	},
	{
		class: "bg-white p-2",
		name: "Policia Nacional",
		img: "https://www.policianacional.gob.do/wp-content/uploads/2024/10/policia-logo-1.svg"
	},
	{
		class: "bg-white p-2",
		name: "DATA-CREDITO",
		img: "https://www.sb.gob.do/media/qsgd1pcl/data-credito.png"
	},
	{
		class: "bg-white p-2",
		name: "indarca",
		img: "https://indarca.net/wp-content/uploads/2019/03/logo.png"
	},
	{
		class: "bg-white p-2",
		name: "plnify",
		img: "https://plnify.com/plnify_logo.png"
	},
	{
		class: "bg-white p-2",
		name: "Bitcoin Curacao",
		img: "https://btccuracao.com/img/logo/logo.png"
	},
	{
		class: "bg-dark p-2",
		name: "Key Kalu",
		img: "https://keykalu.com/wp-content/uploads/elementor/thumbs/LogoKeyKalu-q25zk8m87ga06jtv695qubaxwlarpzac1mjj60oj22.png"
	},

	{
		class: "bg-white p-2",
		name: "ibbundance",
		img: "https://dev.ibbundance.com/static/media/logo_ibbundance_transparent.35efdc22a08b77e7c593.png"
	},
	{
		class: "bg-dark p-2",
		name: "pitbull",

		img: "https://pitbullmusic.com/wp-content/themes/pitbull-theme/assets/img/logo.svg"
	},
	{
		class: "bg-dark p-2",
		name: "campamento baiguate",
		img: "https://campamentobaiguate.com/wp-content/uploads/2022/12/logo.png"
	},
	{
		class: "bg-white p-2",
		name: "PNUD",
		img: "https://www.undp.org/sites/g/files/zskgke326/files/2022-04/pnud-logo-blue.svg"
	},
	{
		class: "bg-dark p-2",
		name: "Signos Framework",
		img: "https://signos-framework.com/wp-content/uploads/2024/03/logo-signos-03-768x421.webp"
	},

	{
		class: "bg-white p-2",
		name: "Lifestyle",
		img: "https://www.lhvcresorts.com/uploads/0000/1/2022/01/05/logosvg.svg"
	},

	{
		class: "bg-dark p-2",
		name: "DIF",
		img: "https://dif.com.do/wp-content/uploads/2024/02/Logo.webp"
	}])
	const settingsMobile = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 1,
	}
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
	}
	return (
		<Container fluid className="py-5 bg-dark">
			<Container>
				<h3 className='text-white'>Brands we have worked with</h3>
				<hr />
				<Row>
					<div className='d-none d-md-block'>
						<Slider {...settings}>
							{records.map((item, index) => {
								console.log(item)

								return (
									<div
										key={index}
										onClick={() => {
											//  history.push(Routes.CompanyDetails.clean + item.id)
										}}
										className={"text-center mx-2 px-4 rounded shadow-sm "}
									>
										<img
											src={item.img}
											alt={item.name}
											style={{
												height: "100px",
												// width: "100px",
												objectFit: "contain",
											}}
											className={item.class ?? ""}
										/>
										{/* <h5>{item.name}</h5> */}

									</div>
								)
							})}
						</Slider>
					</div>
					<div className='d-block d-md-none'>
						<Slider {...settingsMobile}>
							{records.map((item, index) => {
								console.log(item)

								return (
									<div
										key={index}
										onClick={() => {
											//  history.push(Routes.CompanyDetails.clean + item.id)
										}}
										className={"text-center mx-2 px-4 rounded shadow-sm "}
									>
										<img
											src={item.img}
											alt={item.name}
											style={{
												height: "100px",
												// width: "100px",
												objectFit: "contain",
											}}
											className={item.class ?? ""}
										/>
										{/* <h5>{item.name}</h5> */}

									</div>
								)
							})}
						</Slider>
					</div>
				</Row>
			</Container>
		</Container>
	)
}

export default Customers
