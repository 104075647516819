import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, useParams } from "react-router-dom";
import { Routes } from "./routes";
import Corporate from "./components/Corporate/Corporate";


const App = () => {
  // const [isTokenFound, setTokenFound] = useState(false);
  // getTokenExisting(setTokenFound);

  return (
    <Switch>

      <Route path={Routes.Home.path} component={Corporate} />

      <Redirect to={Routes.Home.path} />
    </Switch>
  );
};

export default App;
