
import React, { useEffect, useState } from "react";

import "../../css/corporate.css";

import syncImg from "../../assets/img/sync.svg";
import productsImg from "../../assets/img/products.svg";
import socialImg from "../../assets/img/social.svg";

import technicalExpertise from "../../assets/img/technicalExpertise.jpg";
import indutries from "../../assets/img/industries.jpg";
import multiplatform from "../../assets/img/multiplatform.jpg";
//Translation
import { useTranslation } from "react-i18next";
import Header from "./Header";
import Footer from "./Footer";
import Customers from "./Customers";

const Corporate = () => {


  const { t } = useTranslation("global");

  useEffect(() => {

  }, []);
  return (
    <div className="corporate">
      <Header />

      <header className="masthead" id="home">
        <div className="container px-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <div className="mb-5 mb-lg-0 text-center text-lg-start">
                <h1 className="display-1 lh-1 mb-3">
                  {t("corporate.slide_title")}
                </h1>
                <p className="lead fw-normal text-muted mb-5">
                  {t("corporate.slide_description")}
                </p>
                <div className="d-flex flex-column flex-lg-row align-items-center">
                  <a className="btn btn-primary" href="mailto:info@valegos.com">
                    {t("Let's get started")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="masthead-device-mockup">
                <img src={syncImg}></img>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- Quote/testimonial aside--> */}
      <aside className="text-center bg-gradient-primary-to-secondary">
        <div className="container px-5">
          <div className="row gx-5 justify-content-center">
            <div className="col-xl-8">
              <div className="h2 fs-1 text-white mb-4">
                "To offer the best opportunity of growth for your business, we
                are always reinventing with new trends in the industry"
              </div>
              {/* <img src="assets/img/tnw-logo.svg" alt="..." style="height: 3rem" /> */}
            </div>
          </div>
        </div>
      </aside>
      {/* <!-- App features section--> */}
      <section id="features">
        <div className="container px-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-8 order-lg-1 mb-5 mb-lg-0">
              <div className="container-fluid px-5">
                <div className="row gx-5">
                  <div className="col-md-6 mb-5">
                    {/* <!-- Feature item--> */}
                    <div className="text-start">
                      <i className="bi-phone icon-feature text-gradient d-block mb-3"></i>
                      <h3 className="font-alt">Multiplatform Apps</h3>
                      <p className="text-muted mb-0">
                        Android, iOS, Web, MacOS, Windows & Linux, we can
                        deliver it all. Natively.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    {/* <!-- Feature item--> */}
                    <div className="text-start">
                      <i className="bi-camera icon-feature text-gradient d-block mb-3"></i>
                      <h3 className="font-alt">Integration with Devices</h3>
                      <p className="text-muted mb-0">
                        From Bluetooth, BLE, NFC, RFID, GPS, QR and Bar Codes.
                        Our solutions create the link between multiple
                        environments and creates the sinergy between your
                        services.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-5 mb-md-0">
                    {/* <!-- Feature item--> */}
                    <div className="text-start">
                      <i className="bi-gift icon-feature text-gradient d-block mb-3"></i>
                      <h3 className="font-alt">From A to Z in IT Projects</h3>
                      <p className="text-muted mb-0">
                        We go from planing, wireframing, mockup, graphics,
                        development, QA Testing, production and optimization.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/* <!-- Feature item--> */}
                    <div className="text-start">
                      <i className="bi-patch-check icon-feature text-gradient d-block mb-3"></i>
                      <h3 className="font-alt">Infraestructure as Service</h3>
                      <p className="text-muted mb-0">
                        We can manage your hosted infraestruture for you, That
                        way you can keep the focus on your operations and leave
                        the heavy load of backup, updates, and maintenance to
                        us.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 order-lg-0">
              {/* <!-- Features section device mockup--> */}
              <div className="features-device-mockup">
                <img src={multiplatform}

                  className="img-fluid rounded-circle"
                  alt="" style={{
                    width: "300px",
                    height: "300px",
                    objectFit: "cover",
                  }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Basic features section--> */}
      <section className="bg-light ">
        <div className="container px-5">
          <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
            <div className="col-12 col-lg-5">
              <h2 className="display-4 lh-1 mb-4">
                Need technical expertise in your company?
              </h2>
              <p className="lead fw-normal text-muted mb-5 mb-lg-0">
                We can handle the heavy load of finding the good fit for your
                organization and handle all the mandatory requiriments by local
                goverments. Out source the search of the talent to us.
              </p>
            </div>
            <div className="col-sm-8 col-md-6">
              <div className="px-5 px-sm-0">
                <img
                  className="img-fluid rounded-circle"
                  src={technicalExpertise}
                  alt="..."
                  style={{
                    width: "300px",
                    height: "300px",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Industries--> */}

      <section id="features">
        <div className="container px-5">
          <div className="row gx-5 align-items-center">
            <div className="col-12 mb-5">
              <div className="container-fluid px-5">
                <div className="row gx-5">
                  <div className="col-12">
                    <h2 className="text-center">Industries</h2>
                    <p className="text-center mb-4">A few of the industries we have serve</p>

                  </div>
                  <div className="col-md-4 col-12 col-lg-4 mb-5">
                    {/* <!-- Feature item--> */}
                    <div className="text-start">
                      <i className="fas fa-money-check-alt fa-3x icon-feature text-gradient d-block mb-3"></i>
                      <h3 className="font-alt">Finance</h3>
                      <p className="text-muted mb-0">
                        From banking to financial services, we provide robust IT solutions that enhance operational efficiency, security, and customer experience.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 col-lg-4 mb-5">
                    {/* <!-- Feature item--> */}
                    <div className="text-start">
                      <i className="fas fa-truck-moving fa-3x icon-feature text-gradient d-block mb-3"></i>
                      <h3 className="font-alt">Logistics & Cargo</h3>
                      <p className="text-muted mb-0">
                        We streamline logistics and cargo operations with advanced tracking systems, inventory management, and fleet optimization tools.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4 col-12 col-lg-4 mb-5">
                    {/* <!-- Feature item--> */}
                    <div className="text-start">
                      <i className="fas fa-user-md fa-3x icon-feature text-gradient d-block mb-3"></i>
                      <h3 className="font-alt">Medical</h3>
                      <p className="text-muted mb-0">
                        Our healthcare solutions improve patient management, streamline operations, and ensure compliance with industry standards.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 col-lg-4 mb-5">
                    {/* <!-- Feature item--> */}
                    <div className="text-start">
                      <i className="fas fa-bullhorn fa-3x icon-feature text-gradient d-block mb-3"></i>
                      <h3 className="font-alt">Marketing</h3>
                      <p className="text-muted mb-0">
                        Our marketing solutions leverage data analytics, automation, and creative strategies to drive brand awareness and customer engagement.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 col-lg-4 mb-5">
                    {/* <!-- Feature item--> */}
                    <div className="text-start">
                      <i className="fas fa-music fa-3x icon-feature text-gradient d-block mb-3"></i>
                      <h3 className="font-alt">Entertainment</h3>
                      <p className="text-muted mb-0">
                        We create engaging digital experiences for the entertainment industry, including multimedia platforms, content management systems, and interactive applications.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 col-lg-4 mb-5">
                    {/* <!-- Feature item--> */}
                    <div className="text-start">
                      <i className="fas fa-id-card fa-3x icon-feature text-gradient d-block mb-3"></i>
                      <h3 className="font-alt">HR</h3>
                      <p className="text-muted mb-0">
                        We offer comprehensive HR solutions that simplify recruitment, employee management, and compliance, helping organizations build and maintain a productive workforce.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      {/* WHY valego */}
      <section className="bg-gradient-primary-to-secondary" id="about">
        <div className="container px-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <h2 className="text-white">Why work with us?</h2>
              <p className="text-white">
                Our focus is more than just delivery a project, we always aim to
                bring some value to the table with practical solutions that has
                a real purpose.
              </p>

              <p className="text-white">
                With our more than 15 years of expirience in several industries, we are here to help you with all your IT needs.
              </p>
              <p>
                <button className="btn btn-primary" type="button" onClick={() => window.open("mailto:info@valegos.com")}>
                  Let's hear about your oportunity of growth
                </button>
              </p>
            </div>
            <div className="col-lg-6">
              <div className="masthead-device-mockup">
                <img src={indutries} alt="" className="rounded-circle shadow-lg"
                  style={{
                    width: "300px",
                    height: "300px",
                    objectFit: "cover",
                  }}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- App badge section--> */}
      <section
        className="bg-gradient-primary-to-secondary d-none "
        id="download"
      >
        <div className="container px-5">
          <h2 className="text-center text-white font-alt mb-4">
            {t("corporate.get_app")}!
          </h2>
          <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center">
            <a className="me-lg-3 mb-4 mb-lg-0" href="#!">
              <img
                className="app-badge"
                src="assets/img/google-play-badge.svg"
                alt="..."
              />
            </a>
            <a href="#!">
              <img
                className="app-badge"
                src="assets/img/app-store-badge.svg"
                alt="..."
              />
            </a>
          </div>
        </div>
      </section>
      <Customers />
      <Footer></Footer>
    </div>
  );
};

export default Corporate;
