import React, { useState } from "react";
import { Routes } from "../../routes";
import { useTranslation } from "react-i18next";
import moment from "moment";

const Footer = (props) => {
  const [toggle, setToggle] = useState(false);
  const { t } = useTranslation("global");
  return (
    <footer className="bg-black text-center py-5">
      <div className="container px-5">
        <div className="text-white-50 small">
          <div className="mb-2">
            &copy; {t("webapp.name")} {moment().format("YYYY")}. All Rights
            Reserved.
          </div>
          {/* <a href="#!">Privacy</a>
          <span className="mx-1">&middot;</span>
          <a href="#!">Terms</a>
          <span className="mx-1">&middot;</span>
          <a href="#!">FAQ</a> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
